var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dropdown",
    { attrs: { trigger: "click", "show-timeout": 100 } },
    [
      _c("el-button", { attrs: { plain: "" } }, [
        _vm._v(_vm._s(_vm.getTxt()) + " "),
        _c("i", { staticClass: "el-icon-caret-bottom el-icon--right" }),
      ]),
      _c(
        "el-dropdown-menu",
        {
          staticClass: "no-padding",
          attrs: { slot: "dropdown" },
          slot: "dropdown",
        },
        [
          _c(
            "el-dropdown-item",
            [
              _c(
                "el-radio-group",
                {
                  staticStyle: { padding: "10px" },
                  model: {
                    value: _vm.comment_disabled,
                    callback: function ($$v) {
                      _vm.comment_disabled = $$v
                    },
                    expression: "comment_disabled",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("同意")]),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("不同意")]),
                  _c("el-radio", { attrs: { label: 3 } }, [_vm._v("驳回")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }